.swap {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 2vw;
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      background-color: var(--primary);
      padding: 2vw;
      .tabs {
        width: 100%;
        display: flex;
        height: 50px;
        border-radius: 20px;
        display: flex;
        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;
          flex: 1;
          border-radius: 20px;
          &.active {
            background-color: var(--secondary);
            color: var(--primary);
          }
        }
      }
      .tabContent {
        display: flex;
        flex-direction: column;
        padding: 2vw;
        min-height: 300px;
        .icons {
          width: 100%;
          display: flex;
          justify-content: space-around;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 2px solid var(--secondary);
            &:nth-child(2) {
              transform: rotate(-90deg);
            }
          }
        }
        > div {
          margin-bottom: 10px;
        }
        .input {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          input {
            height: 60px;
            border-radius: 5px;
            padding: 10px;
            flex: 1;
          }
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:20px;
      img {
        width: 300px;
        max-width: 70vw;
      }
      h3 {
        margin: 0;
        padding: 10px 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .swap {
    .top {
      flex-direction: column-reverse;
      padding: 30px;
    }
  }
}
