footer {
  width: 100vw;
  background: linear-gradient(
    137.07deg,
    #8e4a19 22.92%,
    #be4e00 37.86%,
    #fcc704 63.34%
  );
  width: 100vw;
  position: relative;
  .custom-shape-divider-top-1666892474 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-top-1666892474 svg {
    position: relative;
    display: block;
    width: calc(276% + 1.3px);
    height: 147px;
  }

  .custom-shape-divider-top-1666892474 .shape-fill {
    fill: var(--bg);
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    h2 {
      color: #fff;
      font-size: 44px;
    }
    .newsletter {
      form {
        input {
          height: 45px;
          border-radius: 20px;
          padding: 5px 10px;
          background-color: #000000;
          margin: 5px;
          outline: none;
          color: #ffff;
          max-width: 60vw;
        }
        button {
          height: 45px;
          padding: 5px 15px;
          border-radius: 20px;
          background: #f69272;
          margin: 5px;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
    .content {
      color: #fff;

      display: flex;
      justify-content: space-between;
      > div {
        flex: 1;
        padding: 2vw;
      }
      .left {
        h3{
            padding: 0;
            margin: 0;
            margin-bottom: 15px;
        }
       
        ul {
          display: flex;
          flex-direction: column;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            padding: 3px;
            &:hover {
              color: var(--primary);
            }
          }
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        .socials {
          display: flex;
          a {
            font-size: 28px;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: var(--primary);
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        div {
          display: flex;
          align-items: center;
          span {
            margin: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .footer {
    .container {
      h2 {
        font-size: 24px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .footer {
    .container {
      h2 {
        font-size: 24px;
      }
      .content {
        flex-direction: column;
        padding: 3vw;
        .right {
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
}

@media screen and (max-width: 475px) {
}

@media screen and (max-width: 380px) {
}
