.selfieContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vw;
  .placeholder {
    width: 70vw;
    max-width: 300px;
    height: 70vw;
    max-height: 300px;

    border-radius: 50%;
    border: 2px dotted var(--primary);
  }
  video,
  img {
    width: 70vw;
    max-width: 300px;
    height: 70vw;
    max-height: 300px;
    margin: 10px;
  }
}

.select-card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .card {
    border-radius: 5px;
    background-color: var(--secondary);
    color: #fff;
    margin-bottom: 10px;
    max-width: 400px;
    min-width: 250px;
    padding: 5px;
    border: 2px solid transparent;
    &.selected{
      border: 2px solid var(--primary);
    }
  }
}

.card-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  .card-placeholder {
    width: 70vw;
    max-width: 400px;
    border-radius: 5px;
  }
  .photo-taker {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border-radius: 10px;
      margin: 10px;
      max-width: 400px;
      width: 70vw;
    }
    button {
      margin: 10px;
    }
  }
}
