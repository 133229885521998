@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;800;900&display=swap");

:root {
  --bg: #ffffff;
  --bg_secondary: #f5f5f5;
  --primary: #fcc704;
  --primary_dark: #d6aa09;
  --secondary: #8f4a1a;
  --secondary_dark: #693814;
  --bg_gradiant: linear-gradient(
    137.07deg,
    #8e4a19 22.92%,
    #be4e00 37.86%,
    #fcc704 63.34%
  );
  --bg_gradiant_reverse: linear-gradient(
    137.07deg,
    #fcc704 63.34%,
    #8e4a19 22.92%,
    #be4e00 37.86%
  );
  --gray: rgba(217, 217, 217, 0.8);
  --bluish: #bdd9e9;
  --text: #221c5f;
  --shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.dark {
  --bg: #181838;
  --bg_secondary: #182747;
  --primary: #daac08;
  --primary_dark: #d6aa09;
  --secondary: #8f4a1a;
  --secondary_dark: #693814;
  --bg_gradiant: linear-gradient(
    137.07deg,
    #8e4a19 22.92%,
    #be4e00 37.86%,
    #fcc704 63.34%
  );
  --bg_gradiant_reverse: linear-gradient(
    137.07deg,
    #fcc704 63.34% #8e4a19 22.92%,
    #be4e00 37.86%
  );
  --gray: rgba(217, 217, 217, 0.8);
  --bluish: #bdd9e9;
  --text: #ffffff;
  --shadow: 0px 4px 30px rgba(238, 175, 58, 0.1);
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--text);
  overflow-x: hidden;
  background-color: var(--bg);
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-smooth: always;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  transition: all 0.1s ease-in-out;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 86%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 82%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 78%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 72%;
  }
}

.pointer {
  cursor: pointer;
}

body {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90.23deg, #ff6100 0%, #29d6dd 100%);
  }
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f28 {
  font-size: 1.75rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.page_container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw;
  width: inherit;
  width: 100%;
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.btn {
  height: 45px;
  border-radius: 20px;
  padding: 5px 15px;
  width: 160px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary_dark);

  &:hover {
    box-shadow: var(--shadow);
  }
}

.btn_primary {
  background-color: var(--primary);

  &:hover {
    background-color: var(--primary_dark);
  }
}

.btn_secondary {
  background-color: var(--secondary);
  color: #fff;

  &:hover,
  &:focus {
    background-color: var(--secondary_dark);
  }
}

.btn_gradiant {
  background: var(--bg_gradiant);

  &:hover {
    background-color: var(--bg_gradiant_reverse);
  }
}

.btn_large {
  width: 70%;
  min-width: 200px;
  font-size: 18px;
  height: 50px;
  max-width: 60vw;
}

.copyButton {
  background-color: green;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.gradiant_text {
  background: #a725cf;
  background: linear-gradient(to right, #a725cf 0%, #d19f17 69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-left: 20px;

  .tick {
    position: absolute;
    left: -33px;
  }
}

.gradiant_text2 {
  background: #ffb76b;
  background: linear-gradient(
    to right,
    #ffb76b 0%,
    #ffa73d 30%,
    #ff7c00 60%,
    #ff7f04 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-left: 30px;

  .tick {
    position: absolute;
    left: -33px;
  }
}

.gradiant_text3 {
  background: #070c4f;
  background: repeating-radial-gradient(
    ellipse farthest-corner at bottom center,
    #070c4f 0%,
    #cf8311 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-left: 30px;

  .tick {
    position: absolute;
    left: -33px;
  }
}

.gradiant_text4 {
  background: #cf29b3;
  background: linear-gradient(to left, #cf29b3 0%, #1279e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-left: 30px;

  .tick {
    position: absolute;
    left: -33px;
  }
}

.tick {
  margin: 5px;
  color: var(--secondary);
}

body {
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 100vh;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

//common styles
.formContainer {
  display: flex;
  padding: 3vw;

  .form {
    background: var(--bg_gradiant);
    flex: 1;
    padding: 2vw;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      width: 50%;
      &.full_width {
        width: 100%;
      }

      label {
        margin: 5px 0;
      }

      select {
        option {
          padding: 5px;
        }
      }

      input,
      textarea,
      select {
        height: 50px;
        padding: 5px 10px;
        border: none;
        background-color: var(--bg_secondary);
        border-radius: 5px;
        color: var(--text);
        border: 1px solid gray;

        &:focus {
          outline: 2px solid var(--primary);
        }
      }
    }
  }

  .howWorks {
    width: 40vw;
    margin: 0 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;

    img {
      max-width: 500px;
    }
  }

  .howItworks {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    background: var(--bg_gradiant_reverse);
    width: inherit;
    margin: 0 auto;
    border-radius: 20px;
    padding: 20px;

    .howItWorks_items {
      display: flex;
      flex-direction: column;
      width: 100%;

      .item {
        padding: 10px;
        margin: 10px;
        border-radius: 15px;
        background: var(--bg_gradiant);
        width: 70%;
        border: 2px solid var(--secondary);
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          transform: translateX(20px);
        }

        &:nth-child(even) {
          align-self: flex-end;

          &:hover {
            transform: translateX(-20px);
          }
        }

        span {
          padding: 5px;
          font-size: 18px;
          color: #fff;
          display: flex;
          align-items: center;

          span {
            padding: 0 5px;
          }
        }

        .step {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--secondary_dark);
          color: var(--primary);
          font-weight: 800;
          font-size: 24px;
          border: 2px solid var(--primary);
        }

        p {
          margin: 0;
          color: #fff;
          padding: 0 20px;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    img {
      max-width: 40vw;
      border-radius: 20px;
    }
  }
}

.formContainer2 {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);

  padding: 3vw;

  .form2 {
    display: flex;
    max-width: 800px;
    width: 90vw;

    flex-wrap: wrap;

    .input {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 10px 20px;

      label {
        margin: 5px 0;
      }

      select {
        option {
          padding: 5px;
        }
      }

      input,
      .textarea,
      select {
        height: 50px;
        padding: 5px 10px;
        border: none;
        background-color: var(--bg_secondary);
        border-radius: 5px;
        color: var(--text);
        border: 1px solid gray;

        &:focus {
          outline: 2px solid var(--secondary);
        }
      }
    }

    .full_width {
      width: 100% !important;
    }

    .check {
      padding: 10px 20px;
      text-transform: capitalize;
      font-weight: 600;
      display: flex;
      align-items: center;
      width: 100%;

      input {
        margin: 5px;
        height: 20px;
        width: 20px;
      }
    }

    p {
      font-size: 18px;
      padding: 2vw 10px;
      line-height: 30px;
      font-weight: 600;
    }
  }
}

.formContainer3 {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 3vw;

  h2 {
    text-align: center;
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 10px;
    border-bottom: 1px dotted var(--primary);
  }

  .form3 {
    display: flex;
    max-width: 1000px;
    width: 90vw;
    border-radius: 10px;
    padding: 3vw;

    flex-wrap: wrap;
    background-color: var(--primary);

    .input {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 10px 20px;

      label {
        margin: 5px 0;
        color: var(--secondary);
      }

      select {
        option {
          padding: 5px;
        }
      }

      input,
      .textarea,
      select {
        height: 50px;
        padding: 5px 10px;
        border: none;
        background-color: var(--secondary);
        border-radius: 5px;
        color: #fff;
        border: 1px solid gray;
        resize: none;

        &:focus {
          outline: 2px solid var(--primary);
        }
      }
    }

    .full_width {
      width: 100% !important;
    }

    .check {
      padding: 10px 20px;
      text-transform: capitalize;
      font-weight: 600;
      display: flex;
      align-items: center;
      width: 100%;

      input {
        margin: 5px;
        height: 20px;
        width: 20px;
      }
    }

    p {
      font-size: 18px;
      padding: 2vw 10px;
      line-height: 30px;
      font-weight: 600;
    }

    .btn_container {
      display: block;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        width: 60%;
        height: 50px;
        border-radius: 10px;
        outline: none;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  span {
    margin: 0 auto;

    a {
      margin: 0 5px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}

.hero {
  width: 100vw;
  padding: 20px;

  &.payment {
    background-color: var(--primary);
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      align-items: flex-start;
    }

    h2 {
      color: var(--text);
      font-size: calc(3vw + 15px);
    }

    p {
      line-height: 30px;
    }

    .left {
      padding: 3vw;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h3,
      h4 {
        text-align: left;
      }

      p {
        text-align: left;
      }
    }

    .right {
      flex: 1;
      padding: 3vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 500px;
        max-width: 70vw;
        height: auto;
        margin-left: auto;
      }
    }
  }
}

.press {
  background: linear-gradient(180deg, #8e4a19 0%, #fcc704 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

  .container {
    display: flex;
    flex-direction: column;
    padding: 3vw;

    h1 {
      color: #fff;
    }

    .images {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-width: 90vw;
        margin: 20px 0;
      }
    }
  }
}

.whitepaper {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    iframe {
      width: 1000px;
      max-width: 90vw;
      margin: 20px auto;
      padding: 2vw;
      height: 80vh;
      max-height: 80vh;
    }
  }
}

.signin {
  background-color: var(--primary);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin: 20px 0;
      max-width: 70vw;
      width: 600px;
    }
  }
}

@media screen and (max-width: 968px) {
  .howWorks {
    max-width: 40vw;
    margin: 20px;
    display: none;
  }

  .howItworks {
    .howItWorks_items {
      align-self: center;

      .item {
        align-self: center;

        &:nth-child(even) {
          align-self: center !important;
        }

        width: 90%;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .formContainer {
    .form {
      .input {
        width: 100%;

        input {
          height: 45px;
        }
      }
    }

    .howWorks {
      display: none;
    }
  }

  .formContainer2,
  .formContainer3 {
    h5 {
      margin: 0 !important;
      padding: 0;
    }

    .form2,
    .form3 {
      .input {
        width: 100%;
        margin: 0;
        padding: 0 10px;

        input {
          height: 45px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }

  .hero {
    .container {
      flex-direction: column;

      div {
        align-items: center;
      }
    }
  }
}

.otp_input_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  button {
    margin: 0 auto;
  }

  .otp_input {
    display: flex;

    input {
      width: 50px !important;
    }
  }
}

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }

  &:hover {
    ul {
      display: block;
    }
  }

  .select {
    background-color: var(--bg_secondary);
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  ul {
    margin: 0;
    padding: 10px;
    top: 40px;
    list-style: none;
    position: absolute;
    background-color: var(--secondary);
    margin: 5px 0;
    width: 100%;
    border-radius: 5px;
    // display: none;
    z-index: 5;
    transition: all 0.3s;
    color: #fff;
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 3px;
    }

    li {
      display: flex;
      padding: 3px 10px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        flex: 1;
        margin: 0;
        &:nth-child(2) {
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &:last-child {
          align-items: center;
          justify-content: flex-end;
          text-align: right;
        }
        &:first-child {
          text-align: left;
        }
      }

      &:hover {
        background-color: var(--primary);
        border-radius: 4px;
      }
    }
  }
}

.copyButton {
  padding: 5px;

  &:hover {
    background-color: rgb(13, 177, 13);
  }
}

.thankyou_container {
  width: 90vw;
  max-width: 1200px;
  padding: 3vw;
  border-radius: 20px;
  background-color: var(--bg_gradiant);
  background-color: var(--secondary);
  margin: 20px auto;

  h1,
  h2 {
    text-align: center;
    color: var(--primary);
  }

  h2 {
    color: #fff;
  }

  .btn_large {
    min-width: 200px;
    max-width: 70vw;
  }
}

.upload_container {
  padding: 0;

  label {
    margin: 5px;
  }

  .upload {
    height: 50px;
    border-radius: 10px;
    border: 2px dotted var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 5px;
      height: 45px;
      width: auto;
      max-width: 200px;
    }
  }

  input {
    display: none;
  }
}

.items {
  display: flex;
  flex-direction: column;

  .buyCard {
    display: flex;
    justify-content: space-between;

    color: #fff;
    margin-bottom: 10px;
    background-color: var(--secondary);
    border-radius: 10px;

    &:hover {
      background-color: var(--primary_dark);
    }

    div {
      padding: 20px;
      display: flex;

      .key {
        display: none;
      }
    }
  }
}

.track {
  padding: 2vw;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  width: 80vw;
  max-width: 800px;

  .info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .key {
      font-size: 14px;
      font-weight: 600;
      color: var(--primary);
    }

    .value {
      color: #fff;
    }
  }
}

@media screen and (max-width: 600px) {
  .items {
    .buyCard {
      padding: 10px;
      &:first-child {
        display: none;
      }
      flex-direction: column;
      div {
        padding: 5px 10px;
        .key {
          display: block;
          margin-right: 10px;
        }
      }
    }
  }
  .track {
    .info {
      flex-direction: column;

      .key {
        label {
          border-bottom: 1px dotted var(--primary);
        }
      }
    }
  }

  .bubble {
    display: none;
  }
}

.blob2 {
  box-shadow: 10px 10px 5px 0px rgba(224, 60, 207, 0.75);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(224, 60, 207, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(224, 60, 207, 0.75);
}

.bubble {
  box-shadow: 7px -4px 105px -14px rgba(0, 202, 247, 0.69);
  -webkit-box-shadow: 7px -4px 105px -14px rgba(0, 202, 247, 0.69);
  -moz-box-shadow: 7px -4px 105px -14px rgba(0, 202, 247, 0.69);

  border-radius: 50%;
  position: absolute;

  background-color: #00dbde;
  background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);

  cursor: pointer;
  transition: all 0.2s linear;
  animation: move 7s linear infinite;

  &:hover {
    transform: translate(100px, 50px);
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
    opacity: 1;
    width: 20px;
    height: 20px;
  }

  50% {
    transform: translate(-100px, -50px);
    opacity: 0.5;
    width: calc(2vw + 10px);
    height: calc(2vw + 10px);
  }

  75% {
    transform: translate(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: translate(50px, 50px);
    opacity: 1;
    width: 20px;
    height: 20px;
  }
}

.react-responsive-modal-modal {
  background-color: #e76a22 !important;
  background-image: linear-gradient(62deg, #e76a22 0%, #f7ce68 100%) !important;
  border-radius: 10px;
  max-width: 90vw !important;
}
.spinnerContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #050838a9;
  .spinner {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px dotted var(--primary);
    animation: Spin 3s 0.1s infinite;
  }
}

@keyframes Spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
