.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--secondary);
  transform: translateX(-250px);
  transition: all 0.3s ease-in-out;
  z-index: 100 !important;
  color: white;
  position: fixed;
  top: 0;

  &.active {
    transform: translateX(0);
  }

  .wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      height: 80px;
      .menuIcon {
        top: 20px;
        right: -30px;
        position: absolute;
        padding: 10px;
        border-radius: 50%;
        background-color: var(--secondary);
      }
    }

    .links {
      ul {
        padding: 10px;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 15px;
          &:hover {
            color: var(--primary);
            transform: translateX(8px);
          }

        }

        a {
          padding: 10px;
        }
      }
    }
  }
}
