.header {
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  position: sticky;
  position: relative;

  top: 0;
  background-color: var(--bg);
  z-index: 99 !important;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 3vw;
    width: 100%;
    .logo {
      img {
        max-width: 40vw;
      }
    }
    .logo2 {
      display: none;
    }

    .menu {
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;

        li {
          margin-right: 10px;
          font-weight: 500;
          text-transform: capitalize;

          &:hover {
            color: var(--primary);
          }

          a {
            padding: 5px;
          }
        }

        .megaMenuLink {
          .link {
            border-bottom: 2px dotted var(--primary);
            height: 100%;
            &:hover ~ .megaMenu {
              opacity: 1;
              transform: translateX(0px);
              z-index: 999;
            }
          }
          .megaMenu {
            opacity: 0;
            position: absolute;
            transform: translateX(-100vw);
            // display: none;
            z-index: 99999 !important;
            transition: all 0.6s ease-in-out;
            width: 95vw;
            top: 70px;
            left: 0;
            background-color: var(--secondary);
            background: linear-gradient(
              137.07deg,
              #8e4a19 22.92%,
              #be4e00 37.86%,
              #fcc704 63.34%
            );

            border-radius: 20px;
            margin: 0 auto;
            left: 2.5vw;
            box-shadow: var(--shadow);

            .wrapper {
              max-width: 800px;
              display: flex;
              justify-content: space-between;
              padding: 3vw;
              margin: 0 auto;

              ul {
                flex-direction: column;

                li {
                  padding: 5px;
                  margin: 5px;
                  color: #ffffff;

                  &:hover {
                    color: var(--primary);
                  }

                  a {
                  }
                }
              }
            }
            &:hover {
              opacity: 1;
              transform: translateY(20px);
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      .signIn {
        text-transform: uppercase;
        font-weight: 500;
        margin-right: 15px;
        font-weight: 600;
        border-radius: 20px;
        padding: 5px;

        &:hover {
          background-color: var(--primary);
        }
      }

      .wallet {
        padding: 5px 10px;
        border-radius: 20px;

        background: var(--secondary);

        color: #ffffff;
        outline: none;
        border: 1px solid var(--secondary_dark);
        cursor: pointer;
        &:hover {
          background: var(--primary);
        }
      }
      .theme_switcher {
        width: 34px;
        height: 34px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primary);
        margin: 0 10px;
        border-radius: 50%;
        padding: 3px;
        * {
          cursor: pointer;
        }
        &:hover {
          background-color: var(--secondary);
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    .menu {
      display: none;
    }
  }
}

@media screen and (max-width: 668px) {
  .header {
    height: 70px;
    .menu {
    }

    .wallet {
      padding: 5px;
    }
    .actions {
      align-items: center;
    }
  }
}

@media screen and (max-width: 380px) {
  .header {
    .container {
      .logo {
        display: none;
      }
      .logo2 {
        display: inline-block;
        img {
          max-width: 60px;
          margin-left: 2vw;
        }
      }
    }
  }
}
