.kyc {
  padding: 3vw;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: var(--bg_gradiant);
  width: 90vw;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}
