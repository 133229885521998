.hero_container {
  width: 100vw;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .video {
    position: relative;
    height: auto;
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
  }
  .bubbles {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 100px;
    img {
      width: 0;
      animation: bubble 9s linear infinite;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 10px 3px 107px 6px rgba(245, 201, 87, 0.75);
      -webkit-box-shadow: 10px 3px 107px 6px rgba(245, 201, 87, 0.75);
      -moz-box-shadow: 10px 3px 107px 6px rgba(245, 201, 87, 0.75);
      &:nth-child(1) {
        animation-delay: 2s;
      }
      &:nth-child(2) {
        animation-delay: 1s;
      }
      &:nth-child(3) {
        animation-delay: 3s;
      }
      &:nth-child(4) {
        animation-delay: 4.5s;
      }
      &:nth-child(5) {
        animation-delay: 6s;
      }
      &:nth-child(6) {
        animation-delay: 5s;
      }
      &:nth-child(7) {
        animation-delay: 7s;
      }
      &:nth-child(8) {
        animation-delay: 5s;
      }
      &:nth-child(9) {
        animation-delay: 3s;
      }
      &:nth-child(10) {
        animation-delay: 2s;
      }
      &:nth-child(10) {
        animation-delay: 4.5s;
      }
      &:nth-child(10) {
        animation-delay: 7s;
      }
      &:nth-child(10) {
        animation-delay: 3.5s;
      }
    }
  }

  .shape {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    max-width: 70vw;
    width: 100vw;
  }
  .shape2 {
    height: 300px;
    width: 300px;
    max-width: 20vw;
    border-radius: 50%;
    max-height: 20vw;

    border-radius: 50%;
    background-color: #4158d0;

    right: -5vw;
    background-image: linear-gradient(62deg, #f97125 0%, #f2b933 100%);
    position: absolute;
    right: -3vw;
    box-shadow: 4px -6px 15px -3px rgba(227, 110, 29, 0.69);
    -webkit-box-shadow: 4px -6px 15px -3px rgba(227, 110, 29, 0.69);
    -moz-box-shadow: 4px -6px 15px -3px rgba(227, 110, 29, 0.69);
    animation: moveOn 5s ease-in-out infinite;
  }
  .container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: calc(3vw + 15px);

      text-align: center;
      padding: 0;
    }
  }

  .contents {
    .swiper-pagination-bullet {
      background: var(--secondary);
      opacity: 1;
      margin: 0px 3px !important;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 44px;
      border-radius: 50px;
    }
    .single_swiper {
      width: 100%;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2vw;

      .wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        justify-content: space-between;
        height: 100%;
        padding: 3vw;

        .left,
        .right {
          flex: 1;
        }
        .left {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          h1 {
          }
          .buttons {
            display: flex;
            button {
              border-radius: 10px;
              outline: none;
              box-shadow: var(--shadow);
              margin: 10px;
              padding: 5px 15px;
              &.lets_start {
                background-color: var(--secondary);
                color: #fff;
              }
              &.contact_us {
                background-color: var(--bluish);
              }
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 400px;
            width: 60vw;
            max-height: 400px;
          }
        }
      }
    }
  }
}

.features {
  width: 100vw;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;

    h2 {
      font-size: calc(3vw + 15px);

      text-align: center;
      padding: 0;
    }

    p {
      text-align: center;
    }

    .items_container {
      display: flex;

      align-items: center;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2vw;

        div {
          padding: 15px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--bluish);

          img {
            width: 50px;
            height: 50px;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }
}

.howWorks {
  background-color: var(--bg_secondary);
  width: 100vw;
  position: relative;
  padding: 4vw 0;
  .custom-shape-divider-top-1666892304 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-top-1666892304 svg {
    position: relative;
    display: block;
    width: calc(174% + 1.3px);
    height: 147px;
  }

  .custom-shape-divider-top-1666892304 .shape-fill {
    fill: var(--bg);
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 4vw 3vw;

    h2 {
      font-size: calc(3vw + 15px);

      text-align: center;
    }

    img {
      // max-width: 800px;
      margin: 2vw auto;
      max-width: 90vw;
    }

    .items_container {
      display: flex;
      justify-content: space-between;

      h4 {
        text-align: center;
        text-transform: uppercase;

        &:first-child {
          color: #1d46a6;
        }

        &:nth-child(2) {
          color: #a300ad;
        }

        &:nth-child(3) {
          color: #c10032;
        }

        &:nth-child(4) {
          color: #c46b00;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        padding: 2vw;
        align-items: center;

        p {
          text-align: center;
        }
      }
    }

    .counts {
      display: flex;

      border-radius: 10px;
      background: #16123f;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
      margin: 3vw auto;
      max-width: 90vw;

      div {
        padding: 2vw;

        h2 {
          color: #f69272;
          margin: 0;
          padding: 10px;
          font-size: calc(3vw + 15px);
        }

        p {
          color: #fff;
          text-align: center;
        }
      }
    }
  }
}

.passiveIncome {
  position: relative;
  background-color: var(--bg_secondary);
  overflow: hidden;
  position: relative;
  .bubble {
    &:first-child {
      top: 100px;
      left: 100px;
    }
    &:nth-child(2) {
      top: 2vw;
      left: 30vw;
    }
    &:nth-child(3) {
      top: 10vw;
      left: 30vw;
    }
    &:nth-child(4) {
      bottom: 10vw;
      left: 20vw;
    }
    &:nth-child(5) {
      bottom: 5vw;
      right: 10vw;
    }
    &:nth-child(6) {
      top: 5vw;
      right: 20vw;
    }
    &:nth-child(7) {
      top: 15vw;
      left: 10vw;
    }
    &:nth-child(8) {
      bottom: 5vw;
      left: 28vw;
    }
    &:nth-child(9) {
      top: 9vw;
      right: 30vw;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 800px;
    position: relative;

    * {
      z-index: 2;
    }

    .shape {
      position: absolute;
      z-index: 0;
      width: 800px;
    }
    .earth {
      position: absolute;
      left: -10px;
      top: 100px;
      max-width: 300px;
      z-index: -1;
    }

    .earth2 {
      position: absolute;
      right: -10px;
      bottom: 100px;
      max-width: 150px;
      z-index: -1;
    }

    h3 {
      font-size: 28px;
      text-align: center;
      color: var(--secondary);
      margin-bottom: 30px;
      line-height: 72px;
    }

    .items_container {
      display: grid;
      max-width: 700px;
      grid-gap: 15px;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      z-index: 2;

      .item {
        transition: all 0.4s ease-in-out;
        border-radius: 15px;
        background: var(--bg);
        cursor: pointer;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
        box-shadow: -9px -6px 15px 6px rgba(227, 208, 38, 0.69);
        -webkit-box-shadow: -9px -6px 15px 6px rgba(227, 208, 38, 0.69);
        -moz-box-shadow: -9px -6px 15px 6px rgba(227, 208, 38, 0.69);

        &:hover {
          background: #8e4a19;
        }

        display: flex;
        padding: 20px;

        img {
          margin: 5px;
          width: 30px;
          height: 30px;
        }

        div {
          display: flex;
          flex-direction: column;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.topSyrub {
  width: 100vw;
  background-color: var(--secondary);
  position: relative;
  .custom-shape-divider-top-1666891871 {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    height: 300px;
    .shape-fill {
      fill: var(--bg_secondary);
    }
  }

  .container {
    padding: 3vw;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 24px;
      padding: 2vw;
      color: #fff;

      span {
        color: var(--primary);
      }
    }

    .items_container {
      display: flex;
      justify-content: space-between;

      .item {
        padding: 2vw;

        h4 {
          color: var(--primary);
        }

        p {
          color: #fff;
        }
      }
    }
  }
}

.intro {
  position: relative;
  width: 100vw;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../public/images/shape3.png");
  background-size: 500px;
  background-position: center right;
  background-repeat: no-repeat;
  .bubble {
    &:first-child {
      top: 100px;
      left: 100px;
    }
    &:nth-child(2) {
      top: 2vw;
      left: 30vw;
    }
    &:nth-child(3) {
      top: 10vw;
      left: 30vw;
    }
    &:nth-child(4) {
      bottom: 10vw;
      left: 20vw;
    }
    &:nth-child(5) {
      bottom: 5vw;
      right: 10vw;
    }
    &:nth-child(6) {
      top: 5vw;
      right: 20vw;
    }
    &:nth-child(7) {
      top: 15vw;
      left: 10vw;
    }
    &:nth-child(8) {
      bottom: 5vw;
      left: 28vw;
    }
    &:nth-child(9) {
      top: 9vw;
      right: 30vw;
    }
  }

  .container {
    max-width: 1000px;
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
      padding: 3vw;
      align-items: center;

      h2 {
        font-size: 50px;
        background: #d1c664;
        background: repeating-radial-gradient(
          ellipse farthest-corner at bottom center,
          #d1c664 0%,
          #cf8311 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      div {
      }
    }

    .right {
      img {
        max-width: 70vw;
        height: auto;
        width: 300px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .intro {
    .container {
      flex-direction: column;
      align-items: center;

      .left {
        align-items: center;

        h2 {
          font-size: 24px;
        }

        p,
        h2 {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .hero_container {
    height: auto;

    .shape {
      position: absolute;
      top: 30vh;
      right: -100px;
      z-index: -1;
      width: 100vw;
      max-width: 100vw;
      transform: rotate(30deg);
    }
    .shape1 {
      display: none;
    }
    .video {
      display: none;
    }
    .contents {
      .single_swiper {
        height: auto;
        .wrapper {
          flex-direction: column-reverse;
          height: auto;
        }
      }
    }
    .container {
      .playVideo {
        display: none;
      }
    }
  }
  .features {
    .shape1 {
      display: none;
    }
    .items_container {
      flex-direction: column;
      .item {
        img {
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }
  .howWorks {
    .custom-shape-divider-top-1666892304 {
      display: none;
    }
    .container {
      .items_container {
        flex-direction: column;
      }
    }
  }

  .passiveIncome {
    .container {
      .items_container {
        grid-template-columns: 1fr;
        .item {
          flex-direction: column;
          align-items: center;
          padding: 10px;

          div {
            p,
            h4 {
              text-align: center;
              padding: 10px;
              margin: 0;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@keyframes moveOn2 {
  0% {
    transform: scale(0.7);
  }

  50% {
    bottom: 3vw;
    right: 0;
    transform: scale(1);
  }

  100% {
    top: 3vw;
    right: -3vw;
    transform: scale(0.7);
  }
}

@keyframes bubble {
  0% {
    transform: translateY(0);
    opacity: 0;
    width: 20px;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
    width: 30px;
  }
  100% {
    transform: translateY(-100vh);
    width: 60px;
    opacity: 0.2;
  }
}
