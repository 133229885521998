.switcher{
    display: flex;
    border-radius: 20px;
    margin: 0 auto;
    background-color: var(--secondary);
    max-width: 700px;
    padding: 0;
    width: 80vw;
 
    .buy ,.sell{
        padding: 10px 20px;
        flex: 1;
        text-align: center;
      
        &.active{
            border-radius: 20px;
            background-color: white;
            margin: 2px;
            background-color: var(--primary);
        }

    }
}
.buy_sell{
    h4{
        max-width: 70vw!important;
    }

}
