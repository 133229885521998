.vendorLogin {
  display: flex;
  flex-direction: column;
  .content {
    width: 90vw;
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 1vw;

    .tabs {
      display: flex;
      .tab {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        border-radius: 10px;
        color: aliceblue;

        &.active {
          background-color: var(--secondary);
        }
      }
    }
    .tabContent {
      min-height: 400px;
    }
  }
}
