.dashboard {
  display: flex;
  height: 100vh;
  width: 100vw;
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 250px;
    .dashboardMain {
      padding: 3vw;
      overflow-y: scroll;
    }
  }
}

.heading {
  height: 80px !important;
  width: 100%;
  background-color: var(--primary);
  border-bottom: 2px solid var(--secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headingLeft {
    padding-left: 3vw;
    display: flex;
    align-items: center;
    img {
      width: 140px;
      max-width: 35vw;
      margin: 0 2vw;
    }
    h3 {
      color: var(--secondary);
    }
  }
  .headingRight {
    display: flex;
    align-items: center;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      li {
        margin-right: 10px;
      }
    }
    button {
      margin: 20px;
    }
  }
}
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--secondary);
  // transform: translateX(-250px);
  transition: all 0.3s ease-in-out;
  z-index: 100 !important;
  color: white;
  position: fixed;
  top: 0;
  left: 0;

  &.active {
    transform: translateX(0);
  }

  .wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    .top {
      height: 80px;
      .menuIcon {
        top: 20px;
        right: -20px;
        position: absolute;
        padding: 10px;
        border-radius: 50%;
        background-color: var(--secondary);
      }
    }

    .links {
      ul {
        padding: 10px;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 15px;
        }

        a {
          padding: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .dashboard {
    .content {
      margin-left: 0;
    }
  }
  .sidebar {
    transform: translateX(-250px);
    &.active {
      transform: translateX(0);
    }
  }
  .heading {
    .headingLeft {
      justify-content: space-between;
     
    }
    .headingRight {
      display: none;
    }
  }
}
